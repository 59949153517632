import * as React from 'react';
import { INativeInputProps } from '../../ComboBoxInput.types';
import { Arrow } from '../Arrow/Arrow';
import { getAriaAttributes } from '../../../../core/commons/a11y';

const NativeInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  INativeInputProps
> = (props, ref) => {
  const {
    className,
    styles,
    id,
    placeholder,
    onFocus,
    onBlur,
    onMouseDown,
    onKeyDown,
    disabled,
    required,
    value,
    inputValue,
    isOpen,
    setWrapperRef,
    onClick,
    ariaAttributes,
    onInput,
  } = props;

  return (
    <div ref={setWrapperRef} className={styles.selectorWrapper}>
      <input
        className={className}
        onClick={onClick}
        onKeyDown={onKeyDown}
        placeholder={placeholder.text}
        value={inputValue || value}
        disabled={disabled}
        required={required}
        onMouseDown={onMouseDown}
        type="text"
        role="combobox"
        {...getAriaAttributes(ariaAttributes)}
        aria-required={required}
        aria-disabled={disabled}
        aria-autocomplete="list"
        aria-expanded="false"
        aria-controls={id}
        onInput={onInput}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={ref}
      />
      <Arrow styles={styles} isOpen={isOpen} />
    </div>
  );
};

export default React.forwardRef(NativeInput);
